import { BUSINESS_CUSTOMER_STRING } from '~/constants'
import { languageCodeByPath } from '~/helpers/translation-helper'

export default {
  isBusinessCustomerRoute: () => routeObject => routeObject.props.routeConfig.customerType == BUSINESS_CUSTOMER_STRING,

  businessCustomerRootPath: (_state, getters) => languageCode => {
    const routes = getters.getLocalizedRootRoutes(languageCode)
    const matchedRoute = routes.find(route => {
      console.debug('check route to be businessCustomerRootPath', route)
      return getters.isBusinessCustomerRoute(route)
    })
    // HACK: Return any route if no business customer route found to get the app running.
    const path = matchedRoute ? matchedRoute.path : routes[0].path
    console.debug('aggregated businessCustomerRootPath', path)
    return path
  },

  isBusinessCustomerRootRoute: (_state, getters) => route =>
    route.path === getters.businessCustomerRootPath(languageCodeByPath(route.path))
}
