export const CmsPage = () => import('../../components/cms-page/cms-page.vue' /* webpackChunkName: "components/cms-page" */).then(c => wrapFunctional(c.default || c))
export const CardDoubleSided = () => import('../../components/card-double-sided/card-double-sided.vue' /* webpackChunkName: "components/card-double-sided" */).then(c => wrapFunctional(c.default || c))
export const Footer = () => import('../../components/footer/footer.vue' /* webpackChunkName: "components/footer" */).then(c => wrapFunctional(c.default || c))
export const Calculator = () => import('../../components/calculator/calculator.vue' /* webpackChunkName: "components/calculator" */).then(c => wrapFunctional(c.default || c))
export const IceCube = () => import('../../components/ice-cubes/ice-cube.vue' /* webpackChunkName: "components/ice-cube" */).then(c => wrapFunctional(c.default || c))
export const IceCubesContent = () => import('../../components/ice-cubes/ice-cubes-content.vue' /* webpackChunkName: "components/ice-cubes-content" */).then(c => wrapFunctional(c.default || c))
export const IceCubesTestimonial = () => import('../../components/ice-cubes/ice-cubes-testimonial.vue' /* webpackChunkName: "components/ice-cubes-testimonial" */).then(c => wrapFunctional(c.default || c))
export const IceCubes = () => import('../../components/ice-cubes/ice-cubes.vue' /* webpackChunkName: "components/ice-cubes" */).then(c => wrapFunctional(c.default || c))
export const OrderLabelBox = () => import('../../components/order-label__box/order-label__box.vue' /* webpackChunkName: "components/order-label-box" */).then(c => wrapFunctional(c.default || c))
export const OrderLabel = () => import('../../components/orderLabel/orderLabel.vue' /* webpackChunkName: "components/order-label" */).then(c => wrapFunctional(c.default || c))
export const Overlay = () => import('../../components/overlay/overlay.vue' /* webpackChunkName: "components/overlay" */).then(c => wrapFunctional(c.default || c))
export const Pagination = () => import('../../components/pagination/pagination.vue' /* webpackChunkName: "components/pagination" */).then(c => wrapFunctional(c.default || c))
export const TheButtonScrolldown = () => import('../../components/the-button__scrolldown/the-button__scrolldown.vue' /* webpackChunkName: "components/the-button-scrolldown" */).then(c => wrapFunctional(c.default || c))
export const TheHeader = () => import('../../components/the-header/the-header.vue' /* webpackChunkName: "components/the-header" */).then(c => wrapFunctional(c.default || c))
export const TheHeroStageComparison = () => import('../../components/the-hero-stage/the-hero-stage--comparison.vue' /* webpackChunkName: "components/the-hero-stage-comparison" */).then(c => wrapFunctional(c.default || c))
export const TheHeroStageImage = () => import('../../components/the-hero-stage/the-hero-stage--image.vue' /* webpackChunkName: "components/the-hero-stage-image" */).then(c => wrapFunctional(c.default || c))
export const TheHeroStage = () => import('../../components/the-hero-stage/the-hero-stage.vue' /* webpackChunkName: "components/the-hero-stage" */).then(c => wrapFunctional(c.default || c))
export const TheMainNav = () => import('../../components/the-main-nav/the-main-nav.vue' /* webpackChunkName: "components/the-main-nav" */).then(c => wrapFunctional(c.default || c))
export const AppImage = () => import('../../components/app/app__image/app__image.vue' /* webpackChunkName: "components/app-image" */).then(c => wrapFunctional(c.default || c))
export const AppLinkBack = () => import('../../components/app/app__link-back/app__link-back.vue' /* webpackChunkName: "components/app-link-back" */).then(c => wrapFunctional(c.default || c))
export const AppMedia = () => import('../../components/app/app__media/app__media.vue' /* webpackChunkName: "components/app-media" */).then(c => wrapFunctional(c.default || c))
export const AppNavItem = () => import('../../components/app/app__nav-item/app__nav-item.vue' /* webpackChunkName: "components/app-nav-item" */).then(c => wrapFunctional(c.default || c))
export const AppRoutingCache = () => import('../../components/app/app__routing-cache/app__routing-cache.vue' /* webpackChunkName: "components/app-routing-cache" */).then(c => wrapFunctional(c.default || c))
export const AppStatusBox = () => import('../../components/app/app__status-box/app__status-box.vue' /* webpackChunkName: "components/app-status-box" */).then(c => wrapFunctional(c.default || c))
export const AppStatusBoxItem = () => import('../../components/app/app__status-box/app__status-box__item.vue' /* webpackChunkName: "components/app-status-box-item" */).then(c => wrapFunctional(c.default || c))
export const AppVideoDefault = () => import('../../components/app/app__video/app__video-default.vue' /* webpackChunkName: "components/app-video-default" */).then(c => wrapFunctional(c.default || c))
export const AppVideo = () => import('../../components/app/app__video/app__video.vue' /* webpackChunkName: "components/app-video" */).then(c => wrapFunctional(c.default || c))
export const AppYoutube = () => import('../../components/app/app__youtube/app__youtube.vue' /* webpackChunkName: "components/app-youtube" */).then(c => wrapFunctional(c.default || c))
export const CardLogin = () => import('../../components/card/card--login/card--login.vue' /* webpackChunkName: "components/card-login" */).then(c => wrapFunctional(c.default || c))
export const CardWaveGenerator = () => import('../../components/card/card--wave/card--wave-generator.vue' /* webpackChunkName: "components/card-wave-generator" */).then(c => wrapFunctional(c.default || c))
export const CardWave = () => import('../../components/card/card--wave/card--wave.vue' /* webpackChunkName: "components/card-wave" */).then(c => wrapFunctional(c.default || c))
export const CardRegistration = () => import('../../components/card/card-registration/card-registration.vue' /* webpackChunkName: "components/card-registration" */).then(c => wrapFunctional(c.default || c))
export const CardOrder = () => import('../../components/card/cardOrder/cardOrder.vue' /* webpackChunkName: "components/card-order" */).then(c => wrapFunctional(c.default || c))
export const CmsPageContent = () => import('../../components/cms-page/cms-page__content/cms-page__content.vue' /* webpackChunkName: "components/cms-page-content" */).then(c => wrapFunctional(c.default || c))
export const CalculatorBreadcrumb = () => import('../../components/calculator/calculator__breadcrumb/calculator__breadcrumb.vue' /* webpackChunkName: "components/calculator-breadcrumb" */).then(c => wrapFunctional(c.default || c))
export const CalculatorCardInput = () => import('../../components/calculator/calculator__card-input/calculator__card-input.vue' /* webpackChunkName: "components/calculator-card-input" */).then(c => wrapFunctional(c.default || c))
export const CalculatorCardLink = () => import('../../components/calculator/calculator__card-link/calculator__card-link.vue' /* webpackChunkName: "components/calculator-card-link" */).then(c => wrapFunctional(c.default || c))
export const FormCertificateCheck = () => import('../../components/form/form__certificate-check/form__certificate-check.vue' /* webpackChunkName: "components/form-certificate-check" */).then(c => wrapFunctional(c.default || c))
export const FormInput = () => import('../../components/form/form__input/form__input.vue' /* webpackChunkName: "components/form-input" */).then(c => wrapFunctional(c.default || c))
export const FormSelect = () => import('../../components/form/form__select/form__select.vue' /* webpackChunkName: "components/form-select" */).then(c => wrapFunctional(c.default || c))
export const FormSubmit = () => import('../../components/form/form__submit/form__submit.vue' /* webpackChunkName: "components/form-submit" */).then(c => wrapFunctional(c.default || c))
export const MobileTheSplashscreen = () => import('../../components/mobile/mobile__the-splashscreen/mobile__the-splashscreen.vue' /* webpackChunkName: "components/mobile-the-splashscreen" */).then(c => wrapFunctional(c.default || c))
export const OrderCreateBusinessWithoutPayment = () => import('../../components/order-create/order-create__business/order-create__business__without__payment.vue' /* webpackChunkName: "components/order-create-business-without-payment" */).then(c => wrapFunctional(c.default || c))
export const OrderCreateCustomer = () => import('../../components/order-create/order-create__customer/order-create__customer.vue' /* webpackChunkName: "components/order-create-customer" */).then(c => wrapFunctional(c.default || c))
export const OrderCreateOrderData = () => import('../../components/order-create/order-create__order-data/order-create__order-data.vue' /* webpackChunkName: "components/order-create-order-data" */).then(c => wrapFunctional(c.default || c))
export const OrderCreatePaypal = () => import('../../components/order-create/order-create__paypal/order-create__paypal.vue' /* webpackChunkName: "components/order-create-paypal" */).then(c => wrapFunctional(c.default || c))
export const OrderLabelPdfCreate = () => import('../../components/orderLabel/pdf/create.js' /* webpackChunkName: "components/order-label-pdf-create" */).then(c => wrapFunctional(c.default || c))
export const OrderLabelSvgCreate = () => import('../../components/orderLabel/svg/create.js' /* webpackChunkName: "components/order-label-svg-create" */).then(c => wrapFunctional(c.default || c))
export const OrderLabelSvgQrCode = () => import('../../components/orderLabel/svg/qrCode.js' /* webpackChunkName: "components/order-label-svg-qr-code" */).then(c => wrapFunctional(c.default || c))
export const OrderLabelSvgTextToPathConverter = () => import('../../components/orderLabel/svg/textToPathConverter.js' /* webpackChunkName: "components/order-label-svg-text-to-path-converter" */).then(c => wrapFunctional(c.default || c))
export const SectionCardGrid = () => import('../../components/section/section__card-grid/section__card-grid.vue' /* webpackChunkName: "components/section-card-grid" */).then(c => wrapFunctional(c.default || c))
export const SectionCardGridItem = () => import('../../components/section/section__card-grid/section__card-grid__item.vue' /* webpackChunkName: "components/section-card-grid-item" */).then(c => wrapFunctional(c.default || c))
export const SectionCardList = () => import('../../components/section/section__card-list/section__card-list.vue' /* webpackChunkName: "components/section-card-list" */).then(c => wrapFunctional(c.default || c))
export const SectionCardPairGrid = () => import('../../components/section/section__card-pair-grid/section__card-pair-grid.vue' /* webpackChunkName: "components/section-card-pair-grid" */).then(c => wrapFunctional(c.default || c))
export const SectionContentDefault = () => import('../../components/section/section__content-default/section__content-default.vue' /* webpackChunkName: "components/section-content-default" */).then(c => wrapFunctional(c.default || c))
export const SectionContentStriped = () => import('../../components/section/section__content-striped/section__content-striped.vue' /* webpackChunkName: "components/section-content-striped" */).then(c => wrapFunctional(c.default || c))
export const SectionHowto = () => import('../../components/section/section__howto/section__howto.vue' /* webpackChunkName: "components/section-howto" */).then(c => wrapFunctional(c.default || c))
export const SectionProjectDetails = () => import('../../components/section/section__project-details/section__project-details.vue' /* webpackChunkName: "components/section-project-details" */).then(c => wrapFunctional(c.default || c))
export const SectionTestimonialGrid = () => import('../../components/section/section__testimonial-grid/section__testimonial-grid.vue' /* webpackChunkName: "components/section-testimonial-grid" */).then(c => wrapFunctional(c.default || c))
export const SectionTestimonialGridItem = () => import('../../components/section/section__testimonial-grid/section__testimonial-grid__item.vue' /* webpackChunkName: "components/section-testimonial-grid-item" */).then(c => wrapFunctional(c.default || c))
export const TableRowNav = () => import('../../components/table/table__row-nav/table__row-nav.vue' /* webpackChunkName: "components/table-row-nav" */).then(c => wrapFunctional(c.default || c))
export const TheHeaderDropdownNavigation = () => import('../../components/the-header/dropdown-navigation/dropdown-navigation.vue' /* webpackChunkName: "components/the-header-dropdown-navigation" */).then(c => wrapFunctional(c.default || c))
export const TheHeroStageCustomerTypeBusinessCustomerSection = () => import('../../components/the-hero-stage/customer-type/business-customer-section.vue' /* webpackChunkName: "components/the-hero-stage-customer-type-business-customer-section" */).then(c => wrapFunctional(c.default || c))
export const TheHeroStageCustomerTypePrivateCustomerSection = () => import('../../components/the-hero-stage/customer-type/private-customer-section.vue' /* webpackChunkName: "components/the-hero-stage-customer-type-private-customer-section" */).then(c => wrapFunctional(c.default || c))
export const TheHeaderDropdownNavigationCustomerTypeBusinessCustomer = () => import('../../components/the-header/dropdown-navigation/customer-type/business-customer.vue' /* webpackChunkName: "components/the-header-dropdown-navigation-customer-type-business-customer" */).then(c => wrapFunctional(c.default || c))

// nuxt/nuxt.js#8607
function wrapFunctional(options) {
  if (!options || !options.functional) {
    return options
  }

  const propKeys = Array.isArray(options.props) ? options.props : Object.keys(options.props || {})

  return {
    render(h) {
      const attrs = {}
      const props = {}

      for (const key in this.$attrs) {
        if (propKeys.includes(key)) {
          props[key] = this.$attrs[key]
        } else {
          attrs[key] = this.$attrs[key]
        }
      }

      return h(options, {
        on: this.$listeners,
        attrs,
        props,
        scopedSlots: this.$scopedSlots,
      }, this.$slots.default)
    }
  }
}
