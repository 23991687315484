import { render, staticRenderFns } from "./the-hero-stage--comparison.vue?vue&type=template&id=f10e5846&lang=pug&"
import script from "./the-hero-stage--comparison.vue?vue&type=script&lang=js&"
export * from "./the-hero-stage--comparison.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {AppVideoDefault: require('/co2unt/services/customer-app/components/app/app__video/app__video-default.vue').default,AppNavItem: require('/co2unt/services/customer-app/components/app/app__nav-item/app__nav-item.vue').default,AppImage: require('/co2unt/services/customer-app/components/app/app__image/app__image.vue').default,FormCertificateCheck: require('/co2unt/services/customer-app/components/form/form__certificate-check/form__certificate-check.vue').default,TheButtonScrolldown: require('/co2unt/services/customer-app/components/the-button__scrolldown/the-button__scrolldown.vue').default})
