
import BusinessCustomerSection from '~/components/the-hero-stage/customer-type/business-customer-section'
import PrivateCustomerSection from '~/components/the-hero-stage/customer-type/private-customer-section'
import { RoutesMixin, TranslationMixin } from '~/mixins'

export default {
  name: 'TheHeroStageComparison',
  //FIXME: why is this component not autoimported?
  components: { PrivateCustomerSection, BusinessCustomerSection },
  mixins: [RoutesMixin, TranslationMixin],
  props: {
    item: {
      type: Object,
      required: true,
      translations: {
        type: Array,
        required: true
      }
    }
  },
  data() {
    return {
      showInitialContent: true,
      showFirstContent: false,
      showSecondContent: false,
      showFirstImage: true,
      showSecondImage: true,
      activeSide: '',
      navigation: true,
      xChild: 0,
      yChild: 0,
      xParent: 0,
      yParent: 0,
      hideCursor: true,
      cursorDirection: 'cursor--left',
      pointerText: 'Für Mich'
    }
  },
  computed: {
    isFirstSideActive() {
      return this.activeSide === 'first'
    },
    isSecondSideActive() {
      return this.activeSide === 'second'
    },
    cursorCircle() {
      return `left: ${this.xParent}px; top:${this.yParent}px;`
    }
  },
  created() {
    this.setState(this.$router.currentRoute.path)
  },
  mounted() {
    let $theHeroStageComparisonPointer = document.getElementById('TheHeroStageComparisonPointer')

    if ($theHeroStageComparisonPointer) {
      $theHeroStageComparisonPointer.addEventListener('mousemove', this.moveCursor)
      $theHeroStageComparisonPointer.addEventListener('mouseleave', _event => {
        this.hideCursor = true
      })
      $theHeroStageComparisonPointer.addEventListener('mouseenter', _event => {
        this.hideCursor = false
      })
    }
  },
  methods: {
    defaultContent() {
      this.showInitialContent = false
      this.showFirstContent = false
      this.showFirstImage = false
      this.showSecondContent = true
      this.showSecondImage = true
      this.activeSide = 'second'
    },
    businessCustomerContent() {
      this.showInitialContent = false
      this.showFirstContent = false
      this.showFirstImage = false
      this.showSecondContent = true
      this.showSecondImage = true
      this.activeSide = 'second'
    },
    privateCustomerContent() {
      this.showInitialContent = false
      this.showFirstContent = false
      this.showFirstImage = false
      this.showSecondContent = true
      this.showSecondImage = true
      this.activeSide = 'second'
    },
    moveCursor(e) {
      if (!this.navigation) {
        return
      }

      this.xChild = e.clientX
      this.yChild = e.clientY

      setTimeout(() => {
        this.xParent = e.clientX - 15
        this.yParent = e.clientY - 15
      }, 100)

      let cursorPostion = Math.round((this.xChild / window.innerWidth) * 100)

      if (cursorPostion > 50) {
        this.pointerText = this.$t('components.heroStage.compareLabel.business')
        this.cursorDirection = 'cursor--right'
      } else {
        this.pointerText = this.$t('components.heroStage.compareLabel.private')
        this.cursorDirection = 'cursor--left'
      }

      if (cursorPostion < 35) {
        this.privateCustomerContent()
      } else if (cursorPostion > 65) {
        this.businessCustomerContent()
      } else {
        this.defaultContent()
      }
    },
    setState(path) {
      if (this.privateCustomerRootPath == path) {
        this.privateCustomerContent()
        this.navigation = false
      } else if (this.businessCustomerRootPath == path) {
        this.businessCustomerContent()
        this.navigation = false
      } else {
        this.defaultContent()
        this.navigation = true
      }
    }
  },
  watch: {
    $route(to, from) {
      this.setState(to.path)
    }
  }
}
